export default () => {
    return {
        localizables: {
            description: {},
            name: {},
        },
        name: "",
        banner: "",
        description: "",
        skill_data: {
            pvp: {
                trigger_type: 1,
                action_type: 1,
                subject_type: 1,
                value: {
                    duration: 0,
                    effects: []
                },
                description: "",
                localizables: {
                    description: {},
                },
            },
            coop: {
                trigger_type: 1,
                action_type: 1,
                subject_type: 1,
                value: {
                    duration: 0,
                    effects: []
                },
                description: "",
                localizables: {
                    description: {},
                },
            }
        },
        type: 0
    }
}