export default {
    TRIGGER_TYPES: [
        {
            text: 'Merge Unit',
            value: 1
        },
        {
            text: 'Kill Boss',
            value: 2
        },
        {
            text: 'Summon',
            value: 3
        },
        {
            text: 'Duration',
            value: 4
        },
        {
            text: 'Have faction unit on deck',
            value: 5
        }
    ],
    ACTION_TYPES: [
        {
            text: 'Generate Mana',
            value: 1
        },
        {
            text: 'Goes up rank',
            value: 2
        },
        {
            text: 'Attack Speed',
            value: 3
        },
        {
            text: 'Enemy Movement',
            value: 4
        }
    ],
    SUBJECT_TYPES: [
            {
                text: 'None',
                value: 1
            },
            {
                text: 'Random one unit from faction',
                value: 2
            },
            {
                text: 'Every unit from faction',
                value: 3
            },
            {
                text: 'Random x units from faction',
                value: 4
            }
        ],
    VALUE_TYPES: [
            {
                text: 'Number',
                value: 0
            },
            {
                text: 'Percentage',
                value: 1
            },
            {
                text: 'Selection',
                value: 2
            }
        ],
    NEW_SKILL: {
        type: 0,
        amount: 0
    },
    SKILL_TYPES: [
        {
            text: "Routine",
            value: 0
        },
        {
            text: "Cashback",
            value: 1
        },
        {
            text: "Evolution",
            value: 2
        },
        {
            text: "Multiplication",
            value: 3
        },
        {
            text: "Acceleration",
            value: 4
        },
        {
            text: "Starting Mana",
            value: 5
        },
        {
            text: "Marathon",
            value: 6
        },
        {
            text: "Critical Flavour",
            value: 7
        },
    ]

}