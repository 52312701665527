<template>
    <div class="main-container">
      <h1 class="main-heading"> Skill </h1>
      <div class="heading">
      <span>PVP</span>
      <label class="switch">
        <input type="checkbox" v-model="coopSwitch" />
        <span class="slider round"></span>
      </label>
      <span>COOP</span>
    </div>
      <div class="content-container">
        <div class="col">
          <div class="form-container">
              <p>{{ "Language" }}</p>
              <select v-model="selectedLanguage" @change="languageChanged($event)">
                <option v-for="language in languages" v-bind:key="language.code" :value="language.code">{{ language.name }}</option>
              </select>
            </div>
          <div class="form-container">
            <p> Name </p>
            <input v-model="currentSkill.name"/>
          </div>
          <div class="form-container">
            <p> Description </p>
            <input v-model="currentSkill.description"/>
          </div>
          <div class="form-container">
            <p> Localized Name </p>
            <input v-model="currentSkill.localizables.name[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p> Localized Description </p>
            <input v-model="currentSkill.localizables.description[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p> Type </p>
            <select v-model="currentSkill.type" @change="() => setSkillDataSchema()">
              <option v-for="option in skillConstants.SKILL_TYPES" :value="option.value" v-bind:key="option.value" >{{ option.text || "DEFAULT BANNER" }}</option>
            </select>
          </div>
          <div class="form-container">
            <p> Asset </p>
            <select v-model="currentSkill.asset">
              <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
            </select>
          </div>
          <div>
            <h4> Match Type Specific Values {{ !coopSwitch ? ("(Pvp)") : ("(Coop)") }}</h4>
          </div>
          <hr>
        <template v-if="!coopSwitch && currentSkill.skill_data">
          <div class="form-container">
            <p> Description </p>
            <input v-model="currentSkill.skill_data.pvp.description"/>
          </div>
          <div class="form-container">
            <p> Localized Description </p>
            <input v-model="currentSkill.skill_data.pvp.localizables.description[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p> Duration </p>
            <input v-model="currentSkill.skill_data.pvp.value.duration">
          </div>
          <div class="effects-section"></div>
            <div class="row header-row">
                <p>Type</p>
                <p>Amount</p>
            </div>
            <div v-for="(effect, index) in currentSkill.skill_data.pvp.value.effects" v-bind:key="index" class="row">
                <select v-model="currentSkill.skill_data.pvp.value.effects[index].type">
                  <option v-for="option in skillConstants.VALUE_TYPES" :value="option.value" v-bind:key="option.value">{{ option.text || "DEFAULT BANNER" }}</option>
                </select>
              <input type="number" v-model="currentSkill.skill_data.pvp.value.effects[index].amount">
            </div>
            <button class="success" @click="() => addEffect(true)">Add Effect</button>
            <button class="error" @click="() => removeEffect(true)">Remove Effect</button>
        </template>
        <template v-else-if="coopSwitch && currentSkill.skill_data">
          <div class="form-container">
            <p> Description </p>
            <input v-model="currentSkill.skill_data.coop.description"/>
          </div>
          <div class="form-container">
            <p> Localized Description </p>
            <input v-model="currentSkill.skill_data.coop.localizables.description[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p> Duration </p>
            <input v-model="currentSkill.skill_data.coop.value.duration">
          </div>
          <div class="effects-section"></div>
            <div class="row header-row">
                <p>Type</p>
                <p>Amount</p>
            </div>
            <div v-for="(effect, index) in currentSkill.skill_data.coop.value.effects" v-bind:key="index" class="row">
                <select v-model="currentSkill.skill_data.coop.value.effects[index].type">
                  <option v-for="option in skillConstants.VALUE_TYPES" :value="option.value" v-bind:key="option.value">{{ option.text || "DEFAULT BANNER" }}</option>
                </select>
              <input type="number" v-model="currentSkill.skill_data.coop.value.effects[index].amount">
            </div>
            <button class="success" @click="() => addEffect(false)">Add Effect</button>
            <button class="error" @click="() => removeEffect(false)">Remove Effect</button>
        </template>
        </div>
      </div>
      <div class="buttons-section">
        <button @click="openConfirm" class="info">{{ $t('SUBMIT') }}</button>
        <template v-if="$route.query.id">
          <button v-if="env === 'staging' || env === 'prod'" class="info" @click="() => exportTo('dev')">Export to Development</button>
          <button v-if="env === 'dev' || env === 'prod'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
          <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
        </template>
      </div>
      <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType" @confirm="submit"/>
    </div>
  </template>
  
  <script>
  import { onMounted, computed, reactive, watch, ref, onBeforeMount, onBeforeUnmount} from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from "vue-router";
  
  import skillSchema from "@/schemas/skills.js";
  
  import 'v-calendar/dist/style.css';
  import skillConstants from "@/constants/skillConstants";
  
  import languageConstants from "@/constants/languagesTemp";
  import dispatchMap from "@/constants/dispatchMap";
  import { io } from "socket.io-client";
  import ConfirmPopup from '../../../components/common/ConfirmPopup.vue'
  
  export default {
    name: "SkillsAdd",
    components: {
      ConfirmPopup
    },
    setup() {
      const store = useStore();
      const usedPages = ["skillsAdd"];
      const socket = io(process.env.VUE_APP_BACKEND_URL);
      onBeforeMount(() => {
        usedPages.forEach(page => {
            dispatchMap[page].forEach((dispatchStr) => {
              store.dispatch(dispatchStr); 
            })
        });
        }
      );
      const skills = computed(() => store.getters["skills/getSkills"])
      const assetList = computed(() => {
        return [
          {
            doc_ref: undefined
          },
          ...store.getters['assets/getAssets'].files
        ]
      })
      const currentSkill = reactive({...skillSchema()})
      const skillDataSchema = {...skillSchema()};

      const route = useRoute();
      const router = useRouter();
      console.log(router)
      const previousLanguage = ref('en')
      const selectedLanguage = ref('en')
      const languages = languageConstants.languages;
      const coopSwitch = ref(false);
      const popupOpen = ref(false);
      const popupText = ref("Are you sure with these changes?")
      const popupType = ref("refresh")
  
      onMounted(() => {
        if (route.query.id && skills.value.length > 0) {
          const editingSkill = JSON.parse(JSON.stringify(skills.value.find(x => x.id === route.query.id)))
          Object.keys(editingSkill).forEach(key => {
            currentSkill[key] = editingSkill[key]
          })
        }
        if (!currentSkill.localizables) {
          currentSkill.localizables = { name: {}, description: {} }
        }
        if(currentSkill.type === 0)
          delete currentSkill.skill_data;
        store.dispatch("loader/loadingStatus", false)
      })
  
      watch(() => store.getters['skills/getSkills'], value => {
        if (route.query.id) {
          const editingSkill = JSON.parse(JSON.stringify(value.find(x => x.id === route.query.id)))
          Object.keys(editingSkill).forEach(key => {
            currentSkill[key] = editingSkill[key]
          })
          if(currentSkill.type === 0)
            delete currentSkill.skill_data;
        }
      })
      
      onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
  
      const addActionButton = () => {
        currentSkill.action_buttons.push({})
      }
  
      const languageChanged = (event) => {
        previousLanguage.value = event.target.value;
      }
  
      const submit = () => {
        if (route.query.id) {
          store.dispatch('skills/updateSkill', { id: route.query.id, data: currentSkill }).then(() => {
          socket.emit("update", {
            socketId: socket.id,
            itemId: currentSkill.id,
            page: "Skill"
          });
        })
        popupOpen.value = false;
      } else {
        store.dispatch('skills/addSkill', { data: currentSkill }).then(res => {
          if(res) {
            router.push({ name: 'SkillsAdd', query: { id: res.id }})
          }
        })
      }
      }
  
      const exportTo = environment => {
        store.dispatch('skills/exportSkill', { id: route.query.id, data: currentSkill, environment })
      }
  
      socket.on('updated', (data) => {
        if (data.socketId != socket.id && data.itemId === currentSkill.id && data.page === "skillAdd"){
          popupOpen.value = true;
        }
      })

      const addEffect = (isPvp) => {
        if (isPvp)
          currentSkill.skill_data.pvp.value.effects.push(JSON.parse(JSON.stringify(skillConstants.NEW_SKILL)));
        else 
          currentSkill.skill_data.coop.value.effects.push(JSON.parse(JSON.stringify(skillConstants.NEW_SKILL)));
      }

      const removeEffect = (isPvp) => {
        if (isPvp)
          currentSkill.skill_data.pvp.value.effects.splice(-1);
        else 
          currentSkill.skill_data.coop.value.effects.splice(-1);
      }

      const openConfirm = () => {
        popupType.value = "confirm";
        popupOpen.value = true;
      };

      const setSkillDataSchema = () => {
        if(currentSkill.type != 0)
          currentSkill.skill_data = skillDataSchema.skill_data;
        else
          delete currentSkill.skill_data;
      }


  
      const appConfig = computed(() => store.getters['auth/getAppConfig'])
      const env = appConfig.value.appEnv || 'dev'
  
      return {
        currentSkill,
        submit,
        exportTo,
        env,
        addActionButton,
        assetList,
        selectedLanguage,
        languages,
        languageChanged,
        popupText,
        popupOpen,
        popupType,
        coopSwitch,
        skillConstants,
        addEffect,
        removeEffect,
        openConfirm,
        setSkillDataSchema
      }
    }
  }
  </script>
  <style scoped>
  .main-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;
  }
  
  .content-container {
    width: 100%;
    display: grid;
    grid-template-columns: 6fr 4fr;
  }
  
  .form-container {
    display: flex;
    margin: 15px 0;
  }
  
  .form-container p {
    width: 60%;
    text-align: start;
  }
  .form-container input, .form-container div, .form-container select {
    width: 40%;
  }
  #creation_date, #expiration_date {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  
  .buttons-section {
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: stretch;
  }
  
  .buttons-section button {
    font-size: 1.25rem;
  }
  
  button.error {
    background-color: rgba(255, 0, 0, 0.15);
  }
  
  button.success {
    background-color: rgba(0, 255, 0, 0.15);
  }
  
  button.info {
    background-color: rgba(0, 0, 255, 0.15);
  }
  
  .col {
    padding: 15px;
  }
  .row {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr 1fr;
    grid-column-gap: 10px;
    margin: 10px 0;
  }
  .row.header-row {
    background-color: #cccccc;
    font-weight: bold;
  }
  .form-container.inline {
    display: flex;
    flex-direction: column;
  }
  
  .filter-sort {
      margin-top: 30px;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  }
  button {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    border: 1px solid gray;
    padding: 5px 20px;
    margin: 5px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  .flex {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-around;
  align-items: flex-end;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-items {
  width: 150px;
  height: 30px;
  margin: 5px;
  text-align: center;
}
.flex-items2 {
  width: 150px;
  height: 35px;
  margin: 5px;
  text-align: center;
}

.heading span {
  padding: 0 10px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.effects-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: start;
}
.content-container.sub-container {
    border: 1px solid black;
    flex-direction: column;
  }
  
  /*#emoji-palette >>> .ap, .ql-emojiblot >>> .ap {*/
  /*  background-image: none;*/
  /*  text-indent: 0;*/
  /*  !* It's also possible to adjust spacing. To make selecting emojis easier *!*/
  /*  !* width: 25px; *!*/
  /*  !* height: 25px; *!*/
  /*}*/
  </style>